<template>
  <div id="supportTicketDetails">
    <b-row>
      <b-col cols="12" class="mx-auto">
        <div v-if="isLoading" class="text-center">Please wait...</div>
        <b-row v-if="!isLoading && ticket">
          <b-col cols="3">
            <b-card no-body>
              <b-card-header class="border-bottom bg-light p-1"
                ><h5 class="mb-0 p-0">Ticket Information</h5></b-card-header
              >
              <b-card-body class="p-0">
                <div class="border-bottom p-1">
                  <strong>Requester:</strong>
                  <div class="d-flex align-items-center">
                    <b-link :href="`/profile/${ticket.createdBy.username}`" target="_blank">{{
                      ticket.createdBy.username
                    }}</b-link>
                    <b-badge variant="light-primary" class="ml-auto">{{
                      ticket.createdBy.userType
                    }}</b-badge>
                  </div>
                </div>
                <div
                  v-if="ticket.userId.username != ticket.createdBy.username"
                  class="border-bottom p-1"
                >
                  <strong>User:</strong>

                  <div class="d-flex">
                    <b-link target="_blank" :href="`/profile/${ticket.userId.username}`">{{
                      ticket.userId.username
                    }}</b-link>
                    <b-badge variant="light-primary" class="ml-auto">{{
                      ticket.userId.userType
                    }}</b-badge>
                  </div>
                </div>
                <div class="border-bottom p-1">
                  <strong>Last Reply:</strong>

                  <div class="d-flex">
                    <b-link target="_blank" :href="`/profile/${ticket.lastReplyUserId.username}`">{{
                      ticket.lastReplyUserId.username
                    }}</b-link>
                    <b-badge variant="light-primary" class="ml-auto">{{
                      ticket.lastReplyUserId.userType
                    }}</b-badge>
                  </div>
                </div>
                <div class="border-bottom p-1">
                  <strong>Department:</strong><br /><span>{{ ticket.departmentId.name }}</span>
                </div>
                <div class="border-bottom p-1">
                  <strong>Submitted on:</strong><br />{{
                    moment(ticket.createdAt).format("DD MMM YYYY, hh:mm A")
                  }}
                </div>
                <div class="border-bottom p-1">
                  <strong>Last Update: </strong><br />{{ moment(ticket.updatedAt).fromNow() }}
                </div>

                <div class="d-flex">
                  <div class="p-1 w-50">
                    <strong>Priority:</strong><br /><b-badge
                      :variant="`light-${
                        ticketPriorities.find((x) => x.value == ticket.priority).color
                      }`"
                      >{{ ticketPriorities.find((x) => x.value == ticket.priority).text }}</b-badge
                    >
                  </div>
                  <div v-if="ticket.status" class="border-left p-1">
                    <strong>Status:</strong><br />
                    <b-badge :variant="`light-${ticket.status.color}`">{{
                      ticket.status.name
                    }}</b-badge>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="8" class="mx-auto">
            <b-card no-body>
              <b-card-body>
                <h4>Ticket #: {{ ticket.ticketNumber }}</h4>
                <p class="mb-0"><strong>Subject: </strong>{{ ticket.subject }}</p>
              </b-card-body>
            </b-card>
            <b-card v-if="ticket.messages" no-body class="rounded-0">
              <ticket-message
                v-for="(msg, msgIndex) in ticket.messages"
                :key="`msg${msgIndex}`"
                :message="msg"
                :msg-index="msgIndex"
              />
            </b-card>
            <b-card>
              <h5>Reply to the ticket</h5>
              <b-row>
                <b-col cols="12" class="form-group">
                  <quill-editor v-model="replyText" />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3" class="form-group">
                  <label for="status">Change Status to </label>
                  <b-form-select v-model="newStatus" :options="statuses" />
                </b-col>
                <b-col cols="3" class="form-group" v-if="getRole != 'User'">
                  <label for="status">Change Priority to </label>
                  <b-form-select
                    v-model="newPriority"
                    :options="filterEmptyOptions(ticketPriorities)"
                  />
                </b-col>
                <b-col cols="3" class="form-group" v-if="getRole != 'User'">
                  <label for="department">Change Deparment to</label>
                  <b-form-select
                    v-model="newDepartment"
                    :options="filterEmptyOptions(departments)"
                  />
                </b-col>
                <b-col cols="2">
                  <label>&nbsp;</label>
                  <b-button
                    :disabled="
                      !newStatus || (!newPriority && getRole != 'User') || !replyText || replying
                    "
                    variant="primary"
                    block
                    @click="doReply"
                    >{{ replying ? "wait..." : "Reply" }}</b-button
                  >
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col></b-row
    >
  </div>
</template>
<script>
import axios from "axios";
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BBadge,
  BPagination,
  BTable,
  BTextarea,
} from "bootstrap-vue";
import moment from "moment";
import { quillEditor } from "vue-quill-editor";
import TicketMessage from "./TicketMessage";
import useTickets from "../useTickets";

export default {
  name: "SupportTicketDetails",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BLink,
    BBadge,
    BPagination,
    BTable,
    BFormSelect,
    BTextarea,
    TicketMessage,
    quillEditor,
  },
  data() {
    return {
      ticket: null,
      statuses: [],
      departments: [],
      isLoading: false,
      replyText: "",
      newStatus: null,
      newPriority: null,
      newDepartment: null,
      replying: false,
    };
  },
  mounted() {
    this.loadDetails();
    this.loadStatuses();
    this.loadDepartments();
  },
  setup() {
    const { ticketPriorities } = useTickets();

    return { ticketPriorities };
  },
  computed: {
    getRole() {
      return this.$store.getters["auth/getRole"];
    },
  },
  methods: {
    moment(input) {
      return moment(input);
    },
    filterEmptyOptions(options) {
      return options.filter((x) => x.value);
    },
    async loadDetails() {
      try {
        const { id } = this.$route.params;
        this.isLoading = true;
        const { data } = await axios.get(`/support/tickets/details/${id}`);
        this.ticket = data.data;
        this.newStatus = this.ticket.status._id;
        this.newPriority = this.ticket.priority;
        this.newDepartment = this.ticket.departmentId._id;
      } catch (e) {
        this.message = e.message;
      } finally {
        this.isLoading = false;
      }
    },
    async doReply() {
      try {
        this.replying = true;
        const formData = new FormData();
        formData.append("ticketId", this.ticket._id);
        formData.append("message", this.replyText);
        formData.append("ticketStatus", this.newStatus);
        formData.append("priority", this.newPriority);
        const { data } = await axios.put("/support/tickets", formData);
        this.ticket = data.data;
        this.replyText = "";
        this.newStatus = this.ticket.status._id;
        this.newPriority = this.ticket.priority;
      } catch (e) {
        alert(e.message);
      } finally {
        this.replying = false;
      }
    },
    async loadStatuses() {
      try {
        const { data } = await axios.get("/support/status");
        this.statuses = data.data.map((x) => ({
          value: x._id,
          text: x.name,
        }));
      } catch (e) {
      } finally {
      }
    },
    async loadDepartments() {
      try {
        const { data } = await axios.get("/support/departments");
        this.departments = data.data.map((x) => ({
          value: x._id,
          text: x.name,
        }));
      } catch (e) {
      } finally {
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
