var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticketMessage"},[_c('div',{class:{
      'p-1': true,
      'border-bottom': true,
      'bg-light': true,
      'd-flex': true,
      'align-items-center': true,
      'justify-content-between': true,
      'border-top': _vm.msgIndex > 0,
    }},[_c('div',{staticClass:"user"},[_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-link',{attrs:{"target":"_blank","href":("/profile/" + (_vm.message.messageBy.username))}},[_c('b-avatar')],1)]},proxy:true}])},[_c('h5',{staticClass:"mb-0"},[_c('b-link',{attrs:{"target":"_blank","href":("/profile/" + (_vm.message.messageBy.username))}},[_vm._v(_vm._s(_vm.message.messageBy.username))])],1),_c('div',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.message.messageBy.userType))])])],1),_c('div',{staticClass:"status ml-auto text-right"},[_c('b-badge',{attrs:{"variant":("light-" + (_vm.message.ticketStatus.color))}},[_vm._v(_vm._s(_vm.message.ticketStatus.name))]),_c('br'),_vm._v("on "+_vm._s(_vm.moment(_vm.message.dateTime).format("DD MMM YYYY, hh:mm A"))+" ")],1)]),_c('div',{staticClass:"p-1"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.message.message)}}),_c('hr'),_c('div',{staticClass:"text-muted"},[_c('strong',[_vm._v("IP Address")]),_vm._v(": "+_vm._s(_vm.message.ipAddress))])])])}
var staticRenderFns = []

export { render, staticRenderFns }