<template>
  <div class="ticketMessage">
    <div
      :class="{
        'p-1': true,
        'border-bottom': true,
        'bg-light': true,
        'd-flex': true,
        'align-items-center': true,
        'justify-content-between': true,
        'border-top': msgIndex > 0,
      }"
    >
      <div class="user">
        <b-media>
          <template #aside
            ><b-link target="_blank" :href="`/profile/${message.messageBy.username}`"
              ><b-avatar /></b-link
          ></template>
          <h5 class="mb-0">
            <b-link target="_blank" :href="`/profile/${message.messageBy.username}`">{{
              message.messageBy.username
            }}</b-link>
          </h5>
          <div class="text-muted">{{ message.messageBy.userType }}</div>
        </b-media>
      </div>
      <div class="status ml-auto text-right">
        <b-badge :variant="`light-${message.ticketStatus.color}`">{{
          message.ticketStatus.name
        }}</b-badge>
        <br />on {{ moment(message.dateTime).format("DD MMM YYYY, hh:mm A") }}
      </div>
    </div>
    <div class="p-1">
      <div v-html="message.message"></div>
      <hr />
      <div class="text-muted"><strong>IP Address</strong>: {{ message.ipAddress }}</div>
    </div>
  </div>
</template>
<script>
import { BBadge, BMedia, BAvatar, BImage, BLink } from "bootstrap-vue";
import moment from "moment";
export default {
  name: "TicketMessage",
  components: {
    BBadge,
    BMedia,
    BAvatar,
    BImage,
    BLink,
  },
  data() {
    return {};
  },
  methods: {
    moment(input) {
      return moment(input);
    },
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    msgIndex: {
      type: Number,
      required: true,
    },
  },
};
</script>
